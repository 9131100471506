function randomIntFromInterval(min, max) { // min and max included
  let val = Math.floor(Math.random() * (max - min + 1) + min)
  if (Math.random() > 0.5) val *= -1;
  return val
}
window.addEventListener('load', () => {
  const railImgs = document.querySelectorAll('.rail img');

  railImgs.forEach((ri) => ri.style.transform = `rotate(${randomIntFromInterval(9, 15)}deg)`)
})
